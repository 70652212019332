<template>
  <router-view class="summary-router-view">
  </router-view>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
</style>
